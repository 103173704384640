import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image) 
  return (

    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className="relative">
        <div className="relative md:absolute md:top-2 md:right-10 bg-white rounded-lg md:my-10 z-10 md:p-8 w-full md:w-2/5 md:shadow-lg">
          <p className="mb-2 text-lg leading-none font-medium font-bold pb-5 ">
            {data.mdx.frontmatter.title}
          </p>
          <p className="mb-2 text-sm text-gray-700 pb-5">
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
          </p>
          <p className="text-xs text-gray-500">
            W sprzedaży po uprzednim kontakcie
          </p>
        </div>
        <GatsbyImage
          className="rounded-lg mt-10 md:mt-0 md:w-2/3" 
          image={image}
          alt={data.mdx.frontmatter.hero_image_alt}
        />
      </div>
    </Layout>
  )
}
// highlight-start
export const query = graphql`
  query($slug: String) {
    mdx(slug: {eq: $slug}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
// highlight-end

export default BlogPost